import { HttpResponse, delay, type PathParams } from 'msw';

export const getFaqCategoryDetail = async ({
  params,
}: {
  params: PathParams;
}) => {
  await delay(300);

  const id = Number(params.id.toString());
  return HttpResponse.json({
    data: {
      id,
      name: `カテゴリ${id}`,
      slug: `category${id}`,
      parent: id === 1 ? 0 : 1,
    },
  });
};
