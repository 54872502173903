import { HttpResponse, delay } from 'msw';

export const getImportantNewsList = async () => {
  await delay(1000);

  const PER_PAGE_TOTAL = 10;
  const TOTAL = 100;
  const TOTAL_PAGE = 5;

  const importantNewsList = Array.from({ length: PER_PAGE_TOTAL }).map(
    (_, index) => {
      return {
        id: index,
        title: {
          rendered: `重要なお知らせ${index + 1}`,
        },
        date: '2024-07-30T17:48:01',
        acf: {
          important_news_content: `重要なお知らせの内容${index + 1}`,
          attachment_file_1: 1,
          attachment_file_2: 2,
          attachment_file_3: null,
        },
      };
    },
  );

  return HttpResponse.json({
    data: importantNewsList,
    total: TOTAL,
    totalPages: TOTAL_PAGE,
  });
};
