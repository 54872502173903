import { faker } from '@faker-js/faker';
import { HttpResponse, delay } from 'msw';

export const getFaqList = async () => {
  await delay(1000);

  const TOTAL = 3;
  const faqList = Array.from({ length: TOTAL }).map((_, index) => {
    return {
      id: index,
      title: {
        rendered: faker.lorem.sentence(),
      },
      acf: {
        faq_content: faker.lorem.paragraph(),
      },
    };
  });

  return HttpResponse.json({
    data: faqList,
  });
};
