import { faker } from '@faker-js/faker';
import { HttpResponse, delay } from 'msw';

export const getFaqDetail = async () => {
  await delay(1000);

  const id = 100;

  const faqDetail = {
    id,
    title: {
      rendered: `よくあるお問い合わせのタイトル${id}`,
    },
    date: '2024-07-30T17:48:01',
    company: [5, 19],
    'faq-category': [1, 2],
    acf: {
      faq_content:
        '<div><h2 id="1">見出し2</h2><p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p><h3 id="2">見出し3</h3><ul><li>箇条書き</li><li>箇条書き</li><li>箇条書き</li></ul><ol><li>番号付きリスト</li><li>番号付きリスト</li><li>番号付きリスト</li></ol><h4 id="3">見出し4</h4><p>テキストテキスト<strong>太字</strong>テキストテキストテキストテキストテキストテキストテキストテキストテキスト<a href="#">リンク</a></p><blockquote>引用テキスト</blockquote><img src="https://placehold.jp/150x150.png" alt="" /><h2 id="5">見出し2</h2><p>テキストテキストテキストテキストテ</p>',
      hasTableOfContents: faker.helpers.arrayElement([true, false]),
    },
  };

  return HttpResponse.json({
    data: faqDetail,
  });
};
