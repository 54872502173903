import { faker } from '@faker-js/faker';
import { HttpResponse, delay } from 'msw';

export const getCompany = async () => {
  await delay(100);

  return HttpResponse.json({
    data: [
      {
        id: 1,
        name: '株式会社テスト',
        description: 'テスト会社です',
        acf: {
          company_color: faker.helpers.arrayElement([
            'blue',
            'orange',
            'green',
          ]),
          company_top_image: 12,
          company_term: faker.helpers.arrayElement([12, undefined]),
        },
      },
    ],
  });
};
