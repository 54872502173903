import { HttpResponse, delay } from 'msw';

export const getNewsDetail = async () => {
  await delay(1000);

  const id = 100;

  const newsDetail = {
    id,
    title: {
      rendered: `お知らせのタイトル${id}`,
    },
    date: '2024-07-30T17:48:01',
    featured_media: 1,

    acf: {
      news_content:
        'お知らせの内容お知らせの内容お知らせの内容お知らせの内容お知らせの内容お知らせの内容',
      attachment_file_1: 1,
      attachment_file_2: 2,
      attachment_file_3: '',
    },
  };

  return HttpResponse.json({
    data: newsDetail,
  });
};
