import { HttpResponse, delay } from 'msw';

export const getBannerList = async () => {
  await delay(1000);

  const TOTAL = 10;

  const bannerList = Array.from({ length: TOTAL }).map((_, index) => {
    return {
      id: index,
      title: {
        rendered: `バナータイトル${index + 1}バナータイトル${index + 1}バナータイトル${index + 1}バナータイトル${index + 1}バナータイトル${index + 1}`,
      },
      date: '2024-07-30T17:48:01',
      acf: {
        banner_content: `バナーの内容${index + 1}バナーの内容${index + 1}バナーの内容${index + 1}バナーの内容${index + 1}バナーの内容${index + 1}バナーの内容${index + 1}`,
        banner_image: 1,
        banner_link: 'https://www.google.com',
      },
    };
  });

  return HttpResponse.json({
    data: bannerList,
  });
};
