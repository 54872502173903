import { http, type HttpHandler } from 'msw';
import { getBannerList } from './resolvers/getBannerList';
import { getCompany } from './resolvers/getCompany';
import { getFaqCategoryDetail } from './resolvers/getFaqCategoryDetail';
import { getFaqCategoryList } from './resolvers/getFaqCategoryList';
import { getFaqDetail } from './resolvers/getFaqDetail';
import { getFaqList } from './resolvers/getFaqList';
import { getImportantNewsList } from './resolvers/getImportantNewsList';
import { getMedia } from './resolvers/getMedia';
import { getNewsDetail } from './resolvers/getNewsDetail';
import { getNewsList } from './resolvers/getNewsList';

const {
  public: { wpApiBaseUrl },
} = useRuntimeConfig();
const withBaseUrl = (path: string) => `${wpApiBaseUrl}/${path}`;

/**
 * MSWのイベントハンドラー定義
 *
 * NOTE:
 * API仕様書に記載のあるリクエストは Orval が MSW のイベントハンドラーを自動生成するが、
 * 上書きしたい場合はここで定義
 */
export const handlers: HttpHandler[] = [
  http.get(withBaseUrl('important-news'), getImportantNewsList),
  http.get(withBaseUrl('company'), getCompany),
  http.get(withBaseUrl('banner'), getBannerList),
  http.get(withBaseUrl('media/:mediaId'), getMedia),
  http.get(withBaseUrl('news'), getNewsList),
  http.get(withBaseUrl('news/:id'), getNewsDetail),
  http.get(withBaseUrl('faq-category'), getFaqCategoryList),
  http.get(withBaseUrl('faq-category/:id'), getFaqCategoryDetail),
  http.get(withBaseUrl('faq'), getFaqList),
  http.get(withBaseUrl('faq/:id'), getFaqDetail),
];
