import { faker } from '@faker-js/faker';
import { HttpResponse, delay } from 'msw';

export const getFaqCategoryList = async () => {
  await delay(500);

  const PER_PAGE_TOTAL = 5;
  const TOTAL = 100;
  const TOTAL_PAGE = 5;

  const categoryList = Array.from({ length: PER_PAGE_TOTAL }).map(
    (_, index) => {
      return {
        id: index,
        name: `カテゴリ${index + 1}`,
        slug: `category-${index + 1}`,
        parent: faker.helpers.arrayElement([0, 1]),
      };
    },
  );

  return HttpResponse.json({
    data: categoryList,
    total: TOTAL,
    totalPages: TOTAL_PAGE,
  });
};
