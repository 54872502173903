import { faker } from '@faker-js/faker';
import { HttpResponse, delay } from 'msw';

export const getMedia = async () => {
  await delay(1000);

  return HttpResponse.json({
    data: {
      id: faker.number.int(100),
      title: {
        rendered: faker.system.fileName({ extensionCount: 1 }),
      },
      source_url: faker.image.urlLoremFlickr(),
    },
  });
};
