import { HttpResponse, delay } from 'msw';

export const getNewsList = async ({ request }: { request: Request }) => {
  await delay(1000);

  const url = new URL(request.url);
  // urlからsearchParamsのper_pageを取得
  const perPage = Number(url.searchParams.get('per_page') ?? 20);

  const TOTAL_ARTICLE = 100;
  const PERPAGE_ARTICLE = isNaN(perPage) ? 20 : perPage;
  const newsList = Array.from({ length: PERPAGE_ARTICLE }).map((_, index) => {
    return {
      id: index,
      title: {
        rendered: `お知らせ${index + 1}`,
      },
      date: '2024-07-30T17:48:01',
    };
  });

  return HttpResponse.json({
    data: newsList,
    total: TOTAL_ARTICLE,
  });
};
